import { Component } from 'react';

class WorkText extends Component {
    render() {
        return (
            <>
                <span style={{color: 'lightgray'}}>1: </span>
                <span style={{color: 'white'}}>Click or Tap a pic twice for info. </span>
            </>
        );
    }
}

export { WorkText };